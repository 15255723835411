<template>
  <v-container id="lessonQuiz">
    <v-row no-gutters>
      <v-col class="d-flex justify-center align-center">
        <div class="introBox" v-if="!isStarted">
          <!-- <header class="text-center">
            <h3>{{ quiz.courseName }} Eğitimi</h3>
            <p>{{ quiz.lessonName }} Modülü</p>
          </header> -->
          <div class="mb-6 text-center">
            <!-- quizCompletedIcon: Achievement Icon -->
            <span class="icon">
              <i
                class="fa"
                :class="
                  quiz.status === 'passed'
                    ? 'fa-check-circle-o is-active'
                    : 'fa-times-circle'
                "
              ></i>
            </span>

            <p>{{ quiz.dateCreated.toLocaleString("tr-TR") }}</p>

            <!--resultTitleBlock-->
            <h2>
              {{
                quiz.status == "passed"
                  ? "Sınavı başarıyla geçti."
                  : "Sınavı başarıyla geçemedi."
              }}
            </h2>
            <p class="subtitle mt-3">
              Puan: {{ quiz.score }} / {{ quiz.questions.length }}
            </p>
          </div>
          <div class="text-center">
            <v-btn color="primary" rounded @click="start()">İncele</v-btn>
          </div>
        </div>

        <!--questionBox-->
        <div class="questionBox" v-if="isStarted">
          <!-- transition -->
          <transition
            :duration="{ enter: 500, leave: 300 }"
            enter-active-class="animated zoomIn"
            leave-active-class="animated zoomOut"
            mode="out-in"
          >
            <div>
              <!--qusetionContainer-->
              <div
                class="questionContainer"
                v-if="questionIndex < quiz.questions.length"
                v-bind:key="questionIndex"
              >
                <header v-if="showHeader">
                  <!--progress-->
                  <div class="progressContainer">
                    <progress
                      class="progress is-info is-small"
                      :value="(questionIndex / quiz.questions.length) * 100"
                      max="100"
                      >{{
                        (questionIndex / quiz.questions.length) * 100
                      }}%</progress
                    >
                    <p>
                      {{ (questionIndex / quiz.questions.length) * 100 }}%
                      tamamlandı.
                    </p>
                  </div>
                  <!--/progress-->
                </header>

                <!-- questionTitle -->
                <div class="titleContainer">
                  <span class="questionIndex primary--text"
                    >Soru {{ questionIndex + 1 }} /
                    {{ quiz.questions.length }}</span
                  >
                  <!-- {{ clearHtmlTags(questions[questionIndex].text) }} -->
                  <div v-html="quiz.questions[questionIndex].text" />
                </div>

                <!-- quizOptions -->
                <div class="optionContainer">
                  <div
                    class="option"
                    v-for="(option, index) in quiz.questions[questionIndex]
                      .options"
                    :class="{
                      'green white--text':
                        quiz.userResponses[questionIndex] === index &&
                        option.correct,
                      'red white--text':
                        quiz.userResponses[questionIndex] === index &&
                        !option.correct
                    }"
                    :key="index"
                  >
                    <span class="optionIndex" v-if="false">{{
                      index | charIndex
                    }}</span>

                    {{ clearHtmlTags(option.text) }}
                  </div>
                </div>

                <!--quizFooter: navigation and progress-->
                <footer class="questionFooter">
                  <!--pagination-->
                  <nav
                    class="pagination"
                    role="navigation"
                    aria-label="pagination"
                  >
                    <!-- back button -->
                    <v-btn
                      rounded
                      v-on:click="prev()"
                      :disabled="questionIndex < 1"
                      >Geri</v-btn
                    >

                    <!-- finish button -->
                    <v-btn
                      rounded
                      :class="
                        quiz.userResponses[questionIndex] == null
                          ? ''
                          : 'is-active primary'
                      "
                      v-on:click="finish()"
                      :disabled="questionIndex >= quiz.questions.length"
                      >BAŞA DÖN</v-btn
                    >

                    <!-- next button -->
                    <v-btn
                      rounded
                      v-on:click="next()"
                      v-if="questionIndex + 1 != quiz.questions.length"
                      :disabled="questionIndex >= quiz.questions.length"
                      >{{
                        quiz.userResponses[questionIndex] == null
                          ? "Geç"
                          : "İleri"
                      }}</v-btn
                    >
                  </nav>
                  <!--/pagination-->
                </footer>
                <!--/quizFooter-->
              </div>
              <!--/questionContainer-->
            </div>
          </transition>
        </div>
        <!--/questionBox-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    quiz: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    questionIndex: 0,
    isActive: false,
    isStarted: false,
    showHeader: false
  }),
  methods: {
    close: function() {
      this.$emit("close");
    },
    next: function() {
      if (this.questionIndex < this.quiz.questions.length) this.questionIndex++;
    },
    prev: function() {
      if (this.quiz.questions.length > 0) this.questionIndex--;
    },
    start: function() {
      this.isStarted = true;
    },
    finish: function() {
      this.questionIndex = 0;
      this.isStarted = false;
    },
    clearHtmlTags: function(html) {
      const tmp = document.createElement("div");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    }
  }
};
</script>

<style lang="scss" scoped>
#lessonQuiz {
  $trans_duration: 0.3s;
  $primary_color: $color1;

  .button {
    transition: $trans_duration;
  }
  .title,
  .subtitle {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
  }
  .animated {
    transition-duration: $trans_duration/2;
  }

  .container {
    margin: 0 0.5rem;
  }

  .introBox {
    max-width: 30rem;
    width: 30rem;
    background: #fafafa;
    padding: 1.5rem;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    .icon {
      color: #ff5252;
      font-size: 5rem;

      .is-active {
        color: #00e676;
      }
    }
  }

  .questionBox {
    max-width: 30rem;
    width: 30rem;
    min-height: 30rem;

    background: #fafafa;
    position: relative;
    display: flex;

    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    header {
      background: rgba(0, 0, 0, 0.025);
      padding: 1.5rem;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      h1 {
        font-weight: bold;
        margin-bottom: 1rem !important;
      }
      .progressContainer {
        width: 60%;
        margin: 0 auto;
        > progress {
          margin: 0;
          border-radius: 5rem;
          overflow: hidden;
          border: none;

          color: $primary_color;
          &::-moz-progress-bar {
            background: $primary_color;
          }
          &::-webkit-progress-value {
            background: $primary_color;
          }
        }
        > p {
          margin: 0;
          margin-top: 0.5rem;
        }
      }
    }
    .titleContainer {
      // text-align: center;
      margin: 0;
      padding: 1.5rem;
      font-size: 1.2rem;
      overflow: hidden;

      :last-child {
        margin-bottom: 0;
      }

      span {
        display: block;
        font-size: 1rem;
        color: $primary_color;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    .quizForm {
      display: block;
      white-space: normal;

      height: 100%;
      width: 100%;

      .quizFormContainer {
        height: 100%;
        margin: 15px 18px;

        .field-label {
          text-align: left;
          margin-bottom: 0.5rem;
        }
      }
    }

    .questionContainer {
      white-space: normal;

      height: 100%;
      width: 100%;

      .optionContainer {
        margin-top: 12px;
        flex-grow: 1;

        .option {
          border-radius: 10px;
          padding: 9px 18px;
          margin: 0 18px;
          margin-bottom: 12px;
          transition: $trans_duration;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.05);
          color: rgba(0, 0, 0, 0.85);
          border: transparent 1px solid;
          position: relative;

          .optionIndex {
            display: block;
            position: absolute;
            top: -5px;
            left: -5px;
            background-color: $primary_color;
            color: #fff;
            border-radius: 50%;
            font-size: 0.8rem;
            width: 20px;
            height: 20px;
            overflow: hidden;
            text-align: center;
            line-height: 150%;
          }

          &.is-selected {
            border-color: $primary_color;
            background-color: white;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
          &:active {
            transform: scaleX(0.9);
          }
        }
      }

      .questionFooter {
        background: rgba(0, 0, 0, 0.025);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        align-self: flex-end;

        .pagination {
          //padding: 10px 15px;
          margin: 15px 25px;
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: space-between;
  }
  .button {
    padding: 0.5rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5rem;
    margin: 0 0.25rem;

    transition: 0.3s;

    &:hover {
      cursor: pointer;
      background: #eceff1;
      border-color: rgba(0, 0, 0, 0.25);
    }
    &.is-active {
      background: $primary_color;
      color: white;
      border-color: transparent;

      &:hover {
        background: darken($primary_color, 10%);
      }
    }
  }

  @media screen and (min-width: 769px) {
    .questionBox {
      align-items: center;
      justify-content: center;

      .questionContainer {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .sidebar {
      height: auto !important;
      border-radius: 6px 6px 0px 0px;
    }
  }
}
</style>
