var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"lessonQuiz"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[(!_vm.isStarted)?_c('div',{staticClass:"introBox"},[_c('div',{staticClass:"mb-6 text-center"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa",class:_vm.quiz.status === 'passed'
                  ? 'fa-check-circle-o is-active'
                  : 'fa-times-circle'})]),_c('p',[_vm._v(_vm._s(_vm.quiz.dateCreated.toLocaleString("tr-TR")))]),_c('h2',[_vm._v(" "+_vm._s(_vm.quiz.status == "passed" ? "Sınavı başarıyla geçti." : "Sınavı başarıyla geçemedi.")+" ")]),_c('p',{staticClass:"subtitle mt-3"},[_vm._v(" Puan: "+_vm._s(_vm.quiz.score)+" / "+_vm._s(_vm.quiz.questions.length)+" ")])]),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.start()}}},[_vm._v("İncele")])],1)]):_vm._e(),(_vm.isStarted)?_c('div',{staticClass:"questionBox"},[_c('transition',{attrs:{"duration":{ enter: 500, leave: 300 },"enter-active-class":"animated zoomIn","leave-active-class":"animated zoomOut","mode":"out-in"}},[_c('div',[(_vm.questionIndex < _vm.quiz.questions.length)?_c('div',{key:_vm.questionIndex,staticClass:"questionContainer"},[(_vm.showHeader)?_c('header',[_c('div',{staticClass:"progressContainer"},[_c('progress',{staticClass:"progress is-info is-small",attrs:{"max":"100"},domProps:{"value":(_vm.questionIndex / _vm.quiz.questions.length) * 100}},[_vm._v(_vm._s((_vm.questionIndex / _vm.quiz.questions.length) * 100)+"%")]),_c('p',[_vm._v(" "+_vm._s((_vm.questionIndex / _vm.quiz.questions.length) * 100)+"% tamamlandı. ")])])]):_vm._e(),_c('div',{staticClass:"titleContainer"},[_c('span',{staticClass:"questionIndex primary--text"},[_vm._v("Soru "+_vm._s(_vm.questionIndex + 1)+" / "+_vm._s(_vm.quiz.questions.length))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.quiz.questions[_vm.questionIndex].text)}})]),_c('div',{staticClass:"optionContainer"},_vm._l((_vm.quiz.questions[_vm.questionIndex]
                    .options),function(option,index){return _c('div',{key:index,staticClass:"option",class:{
                    'green white--text':
                      _vm.quiz.userResponses[_vm.questionIndex] === index &&
                      option.correct,
                    'red white--text':
                      _vm.quiz.userResponses[_vm.questionIndex] === index &&
                      !option.correct
                  }},[(false)?_c('span',{staticClass:"optionIndex"},[_vm._v(_vm._s(_vm._f("charIndex")(index)))]):_vm._e(),_vm._v(" "+_vm._s(_vm.clearHtmlTags(option.text))+" ")])}),0),_c('footer',{staticClass:"questionFooter"},[_c('nav',{staticClass:"pagination",attrs:{"role":"navigation","aria-label":"pagination"}},[_c('v-btn',{attrs:{"rounded":"","disabled":_vm.questionIndex < 1},on:{"click":function($event){return _vm.prev()}}},[_vm._v("Geri")]),_c('v-btn',{class:_vm.quiz.userResponses[_vm.questionIndex] == null
                        ? ''
                        : 'is-active primary',attrs:{"rounded":"","disabled":_vm.questionIndex >= _vm.quiz.questions.length},on:{"click":function($event){return _vm.finish()}}},[_vm._v("BAŞA DÖN")]),(_vm.questionIndex + 1 != _vm.quiz.questions.length)?_c('v-btn',{attrs:{"rounded":"","disabled":_vm.questionIndex >= _vm.quiz.questions.length},on:{"click":function($event){return _vm.next()}}},[_vm._v(_vm._s(_vm.quiz.userResponses[_vm.questionIndex] == null ? "Geç" : "İleri"))]):_vm._e()],1)])]):_vm._e()])])],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }