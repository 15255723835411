





















































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";
import Confirm from "@/components/Confirm.vue";
import InviteUser from "./InviteUser.vue";
import UserDetail from "./UserDetail.vue";
import AddRemoveCourse from "./AddRemoveCourse.vue";

type DownloadItem = {
  "Davetiye Kodu": string;
  "Kullanıcı Adı": string;
  "e-Posta": string;
  "Davet Tarihi": string;
  "Kabul Tarihi": string;
  Durum: string;
};

type business = {
  id: string;
  code: string;
  name: string;
  regId: string;
  logo: string;
  colors: {
    primary: string;
    secondary: string;
    navbar: string;
    navbarText: string;
  };
  registrations: businessReg[];
};

type businessReg = {
  id: string;
  businessId: string;
  businessCode: string;
  businessName: string;
  dateCreated: Date;
  dateExpired: Date;
  disabled: boolean;
  maxUsers: number;
  activeUsers: number;
  passiveUsers: number;
};

type userInvitation = {
  id: string;
  uid: string | null;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  roleId: string;
  dateCreated: Date;
  dateOpened: Date | null;
  isAccepted: boolean;
  businessId: string;
  businessCode: string;
  businessName: string;
  businessRegId: string;
};

export default Vue.extend({
  components: {
    Confirm,
    InviteUser,
    UserDetail,
    AddRemoveCourse
  },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "Davetiye Kodu", value: "id" },
      { text: "İsim", value: "displayName" },
      { text: "e-Posta", value: "email" },
      { text: "Davet Tarihi", value: "dateCreated", width: "160px" },
      { text: "Kabul Tarihi", value: "dateOpened", width: "160px" },
      { text: "Durum", value: "isAccepted", width: "90px" },
      { text: "", value: "actions", sortable: false, width: "50px" }
    ],
    businessReg: {} as businessReg,
    userInvitations: [] as userInvitation[],
    selectedUserInvitation: {} as userInvitation,
    dialog: false,
    dialogContent: "",
    downloadData: [] as DownloadItem[]
  }),
  computed: {
    selectedItemProps(): userInvitation | businessReg | {} {
      if (this.dialogContent === "UserDetail") {
        return {
          userInvitation: this.selectedUserInvitation
        };
      } else if (this.dialogContent === "EditUser") {
        return {
          userInvitation: this.selectedUserInvitation
        };
      } else if (this.dialogContent === "InviteUser") {
        return {
          business: this.business,
          businessReg: this.businessRegistration
        };
      } else if (this.dialogContent === "AddRemoveCourse") {
        return {
          user: {
            id: this.selectedUserInvitation.uid,
            displayName: this.selectedUserInvitation.displayName,
            email: this.selectedUserInvitation.email
          }
        };
      } else {
        return {};
      }
    },

    user(): any {
      return this.$store.getters["auth/user"];
    },

    business(): business {
      return this.$store.getters["auth/business"];
    },

    businessRegistration(): businessReg {
      return this.$store.getters["auth/businessRegistration"];
    }
  },

  methods: {
    checkBusinessReg() {
      if (this.businessRegistration.disabled) {
        this.$notify({
          type: "warning",
          title: "Uyarı!",
          text:
            "Bu abonelik döneminin süresi dolduğundan kullanıcı işlemleri erişilemez."
        });

        return false;
      } else {
        return true;
      }
    },

    openUser(item: userInvitation) {
      if (!this.checkBusinessReg()) return;

      this.dialogContent = "UserDetail";
      this.selectedUserInvitation = item;
      this.dialog = true;
    },

    inviteUser() {
      if (!this.checkBusinessReg()) return;

      this.dialogContent = "InviteUser";
      this.dialog = true;
    },

    async deleteUserInvitation(invitation: userInvitation) {
      if (!this.checkBusinessReg()) return;

      // eslint-disable-next-line
      const confirmation = await (this.$refs.confirm as any).open(
        "Davetiye Silinecek",
        `Bu işlem geri alınamaz. <strong>${invitation.displayName}</strong> adına düzenlenen davetiyeyi silmek istediğinizden emin misiniz?`,
        {
          color: "red"
        }
      );

      if (confirmation) {
        this.$notify({
          text: "Kullanıcı davetiyesi siliniyor. Lütfen bekleyin..."
        });

        try {
          // Kullanıcının davetiyesini sil
          await firebase
            .firestore()
            .collection("businessUserInvitations")
            .doc(invitation.id)
            .delete();

          // İşyeri kaydındaki aktif/pasif kullanıcı sayılarını güncelle
          let updateStatement = {};

          if (invitation.isAccepted) {
            // Aktif kullanıcı sayısını azalt
            updateStatement = {
              activeUsers: firebase.firestore.FieldValue.increment(-1)
            };
          } else {
            // Pasif kullanıcı sayısını azalt
            updateStatement = {
              passiveUsers: firebase.firestore.FieldValue.increment(-1)
            };
          }

          await firebase
            .firestore()
            .doc("businessRegistrations/" + this.businessRegistration.id)
            .update(updateStatement);

          this.$notify({
            type: "success",
            text: "Kullanıcı davetiyesi başarıyla silindi."
          });

          // Verileri güncelle
          this.getItems();
        } catch (e) {
          const err = e as firebase.firestore.FirestoreError;
          this.$notify({
            type: "error",
            text: `HATA: Kullanıcı davetiyesi silinemedi. ${err.code} ${err.message}`
          });
        }
      }
    },

    async sendInvitationEmail(invitation: userInvitation) {
      if (!this.checkBusinessReg()) return;

      // eslint-disable-next-line
      const confirmation = await (this.$refs.confirm as any).open(
        "Emin misiniz?",
        `<strong>${invitation.displayName}</strong> adına düzenlenen davetiyeyi tekrar göndermek istediğinize emin misiniz?`,
        {
          color: "red"
        }
      );

      if (confirmation) {
        this.$notify({
          text: "Davetiye gönderiliyor. Lütfen bekleyin..."
        });

        try {
          await this.$store.dispatch("auth/sendInvitationEmail", invitation.id);

          this.$notify({
            type: "success",
            text: "Davetiye başarıyla gönderildi."
          });
        } catch (e) {
          const err = e as firebase.firestore.FirestoreError;
          this.$notify({
            type: "error",
            text: `HATA: Davetiye gönderilemedi. ${err.message}`
          });
        }
      }
    },

    closeDialog() {
      this.dialog = false;
      this.dialogContent = "";
      //this.selectedUserInvitation = {} as userInvitation;
    },

    async getItems() {
      this.loading = true;

      try {
        this.userInvitations = [];

        // // İşyeri kaydını al
        // const regDoc = await firebase
        //   .firestore()
        //   .doc("businessRegistrations/" + this.business.regId)
        //   .get();

        // if (!regDoc.exists) throw new Error("İşyeri kaydı bulunamadı!");

        // const regData = regDoc.data();

        // if (regData) {
        //   this.businessReg = {
        //     id: regDoc.id,
        //     businessId: regData.businessId,
        //     businessCode: regData.businessCode,
        //     businessName: regData.businessName,
        //     dateCreated: regData.dateCreated,
        //     dateExpired: regData.dateExpired,
        //     maxUsers: regData.maxUsers,
        //     activeUsers: regData.activeUsers,
        //     passiveUsers: regData.passiveUsers,
        //     disabled: regData.disabled
        //   };
        // }

        // Get all users invitations
        const invitationQs = await firebase
          .firestore()
          .collection("businessUserInvitations")
          .where("roleId", "!=", "admin")
          .where("businessRegId", "==", this.businessRegistration.id)
          .get();

        invitationQs.forEach(async invitationDoc => {
          if (invitationDoc.exists) {
            const invitationData = invitationDoc.data();
            const newInvitation: userInvitation = {
              id: invitationDoc.id,
              uid: invitationData.uid,
              firstName: invitationData.firstName,
              lastName: invitationData.lastName,
              displayName: `${invitationData.firstName} ${invitationData.lastName}`,
              email: invitationData.email,
              roleId: invitationData.roleId,
              dateCreated: invitationData.dateCreated.toDate(),
              dateOpened:
                invitationData.dateOpened === null
                  ? null
                  : invitationData.dateOpened.toDate(),
              isAccepted: invitationData.isAccepted,
              businessId: invitationData.businessId,
              businessCode: invitationData.businessCode,
              businessName: invitationData.businessName,
              businessRegId: invitationData.businessRegId
            };

            this.userInvitations.push(newInvitation);

            // İndirme verisini oluştur
            const downloadItem = {
              "Davetiye Kodu": newInvitation.id,
              "Kullanıcı Adı": newInvitation.displayName,
              "e-Posta": newInvitation.email,
              "Davet Tarihi": newInvitation.dateCreated.toLocaleString("tr-TR"),
              "Kabul Tarihi":
                newInvitation.dateOpened == null
                  ? ""
                  : newInvitation.dateOpened?.toLocaleString("tr-TR"),
              Durum: newInvitation.isAccepted ? "Onaylandı" : "Onaylanmadı"
            } as DownloadItem;

            this.downloadData.push(downloadItem);
          }
        });

        this.loading = false;
      } catch (e) {
        const err = e as firebase.firestore.FirestoreError;
        this.loading = false;
        this.$notify({
          type: "error",
          text: `HATA: Kullanıcı davetiyeleri alınamadı. ${err.code} ${err.message}`
        });
      }
    },

    async addRemoveCourse(item: userInvitation) {
      if (!this.checkBusinessReg()) return;

      this.selectedUserInvitation = item;
      this.dialogContent = "AddRemoveCourse";
      this.dialog = true;
    }
  },

  async mounted() {
    await this.getItems();

    this.$watch("businessRegistration", async function() {
      await this.getItems();
    });
  }
});
