<template>
  <div>
    <v-card>
      <v-form ref="form" @submit.prevent="invite()">
        <v-card-title>Kullanıcı Davet Et</v-card-title>
        <v-divider />
        <v-card-text>
          <div class="d-flex justify-space-around">
            <v-text-field
              id="firstName"
              v-model="form.firstName"
              label="Ad"
              name="firstName"
              prepend-icon="person"
              type="text"
              required
              :rules="firstNameRules"
            />
            <v-text-field
              id="lastName"
              v-model="form.lastName"
              label="Soyad"
              name="lastName"
              type="text"
              required
              :rules="lastNameRules"
              class="ml-4"
            />
          </div>
          <v-text-field
            id="email"
            v-model="form.email"
            label="e-Posta"
            name="email"
            prepend-icon="alternate_email"
            type="text"
            required
            :rules="emailRules"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">İptal Et</v-btn>
          <v-btn color="primary" type="submit" text :disabled="submitDisabled"
            >Ekle</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  props: {
    business: {
      type: Object,
      required: true
    },
    businessReg: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    roles: [
      { id: "student", title: "Öğrenci" },
      { id: "instructor", title: "Eğitmen" },
      { id: "admin", title: "Sistem Yöneticisi" }
    ],
    form: {
      firstName: "",
      lastName: "",
      email: "",
      roleId: "student"
    },
    submitDisabled: false
  }),
  computed: {
    firstNameRules() {
      return [v => !!v || "Lütfen adınızı yazınız"];
    },
    lastNameRules() {
      return [v => !!v || "Lütfen soyadınızı yazınız"];
    },
    emailRules() {
      return [
        v => !!v || "Lütfen e-postanızı yazınız",
        v => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
      ];
    },
    roleRules() {
      return [v => (v && v.length > 0) || "Lütfen rol belirtiniz."];
    }
  },
  methods: {
    close() {
      this.reset();
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async invite() {
      if (this.validate()) {
        this.submitDisabled = true;
        this.$notify({
          text: "Davetiye oluşturuluyor. Lütfen bekleyin..."
        });

        try {
          const newInvitation = {
            uid: null,
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            email: this.form.email,
            businessRegId: this.businessReg.id,
            businessId: this.business.id,
            businessCode: this.business.code,
            businessName: this.business.name,
            roleId: "student",
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
            dateOpened: null,
            isAccepted: false
          };

          // Aynı kişi adına düzenlenmiş bir davetiye var mı kontrol et.
          const checkQs = await firebase
            .firestore()
            .collection("businessUserInvitations")
            .where("email", "==", newInvitation.email)
            .where("businessRegId", "==", this.businessReg.id)
            .get();

          if (checkQs.size !== 0) {
            this.$notify({
              type: "error",
              text: `${newInvitation.email} adına düzenlenmiş bir davetiye zaten var.`
            });

            return;
          }

          // Davetiye oluştur
          await firebase
            .firestore()
            .collection("businessUserInvitations")
            .add(newInvitation);

          // İşyeri kaydındaki pasif kullanıcı sayısını güncelle
          await firebase
            .firestore()
            .doc("businessRegistrations/" + this.businessReg.id)
            .update({
              passiveUsers: firebase.firestore.FieldValue.increment(1)
            });

          setTimeout(() => {
            this.submitDisabled = false;
          }, 1000);

          this.$notify({
            type: "success",
            text: "Davetiye başarıyla oluşturuldu."
          });

          this.close();
          this.refresh();
        } catch (err) {
          this.submitDisabled = false;

          this.$notify({
            type: "error",
            text: `Davetiye oluşturulamadı. ${err.code} ${err.message} ${err.details}`
          });
        }
      }
    }
  }
};
</script>
